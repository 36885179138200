import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnergyType } from "../../types/kpi-overview.interface";
import { OperationalParametersType } from "../../types/operational-parameters.type";
import { EChartsOption } from "echarts";

@Component({
  selector: 'co2-operational-parameters',
  templateUrl: './operational-parameters.component.html',
  styleUrls: ['./operational-parameters.component.scss']
})
export class OperationalParametersComponent {
  @Input() energyType!: EnergyType | string;
  @Input() showedTypes!: OperationalParametersType[] | undefined;

  @Input() powerGraphInputs!: EChartsOption;
  @Input() powerGraphIsLoading!: boolean | null;
  @Input() hasPowerGraphLoadingError!: boolean | null;
  @Output() reloadPowerGraphData = new EventEmitter();

  @Input() waterFlowratesGraphInputs!: EChartsOption;
  @Input() waterFlowratesGraphIsLoading!: boolean | null;
  @Input() hasWaterFlowratesGraphLoadingError!: boolean | null;
  @Output() reloadWaterFlowratesGraphData = new EventEmitter();

  @Input() pressureGraphInputs!: EChartsOption;
  @Input() pressureGraphIsLoading!: boolean | null;
  @Input() hasPressureGraphLoadingError!: boolean | null;
  @Output() reloadPressureGraphData = new EventEmitter();

  @Input() fuelGasFlowratesGraphInputs!: EChartsOption;
  @Input() fuelGasFlowratesGraphIsLoading!: boolean | null;
  @Input() hasFuelGasFlowratesGraphLoadingError!: boolean | null;
  @Output() reloadFuelGasFlowratesGraphData = new EventEmitter();

  @Input() deliveredPowerGraphInputs!: EChartsOption;
  @Input() deliveredPowerGraphIsLoading!: boolean | null;
  @Input() hasDeliveredPowerGraphLoadingError!: boolean | null;
  @Output() reloadDeliveredPowerGraphData = new EventEmitter();

  @Input() processGasFlowrateGraphInputs!: EChartsOption;
  @Input() processGasFlowrateGraphIsLoading!: boolean | null;
  @Input() hasProcessGasFlowrateGraphLoadingError!: boolean | null;
  @Output() reloadProcessGasFlowrateGraphData = new EventEmitter();

  @Input() suctionDischargePressureGraphInputs!: EChartsOption;
  @Input() suctionDischargePressureGraphIsLoading!: boolean | null;
  @Input() hasSuctionDischargePressureGraphLoadingError!: boolean | null;
  @Output() reloadSuctionDischargePressureGraphData = new EventEmitter();

  @Input() suctionDischargeTemperatureGraphInputs!: EChartsOption;
  @Input() suctionDischargeTemperatureGraphIsLoading!: boolean | null;
  @Input() hasSuctionDischargeTemperatureGraphLoadingError!: boolean | null;
  @Output() reloadSuctionDischargeTemperatureGraphData = new EventEmitter();


  @Output() toggleGraph = new EventEmitter<string>();

  OPERATIONAL_PARAMETERS_GRAPH = OperationalParametersType;

  onToggleGraph(event: any, graph: OperationalParametersType): void {
    if (event.target.ariaExpanded === "true" ||
      event.target.attributes["aria-expanded"]?.value === "true") {
      this.toggleGraph.emit(OperationalParametersType[graph]);
    }
  }

  containsType(type: OperationalParametersType) {
    return this.showedTypes?.includes(type) || false;
  }
}
