import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'co2-operational-parameters-item',
  templateUrl: './operational-parameters-item.component.html',
  styleUrls: ['./operational-parameters-item.component.scss']
})
export class OperationalParametersItemComponent {
  @Input() itemId!: string;
  @Input() headerText!: string;
  @Input() isLoading!: boolean | null;
  @Input() graphInputs: any;
  @Input() hasLoadingError!: boolean | null;
  @Output() reloadGraphData = new EventEmitter<void>();
  @Output() toggleGraph = new EventEmitter<Event>();
}
