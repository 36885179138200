<h4 class="accordion-header" [id]="itemId.concat('-header')">
  <button class="accordion-button collapsed" type="button" [id]="itemId.concat('-btn')"
          data-bs-toggle="collapse" [attr.data-bs-target]="'#' + itemId.concat('-body')"
          aria-expanded="false" [attr.aria-controls]="itemId.concat('-body')"
          (click)="toggleGraph.emit($event)">
    <span class="material-icons-round accordion-header-icon">graphic_eq</span>
    <span>{{ headerText }}</span>
  </button>
</h4>
<div [id]="itemId.concat('-body')" class="accordion-collapse collapse"
     [attr.aria-labelledby]="itemId.concat('-header')">
  <div class="accordion-body">
    <co2-loading-spinner [isLoading]="isLoading">
      <co2-ui-graph *ngIf="graphInputs" [options]="graphInputs"></co2-ui-graph>
      <co2-card-error *ngIf="hasLoadingError" (tryAgain)="reloadGraphData.emit()"></co2-card-error>
    </co2-loading-spinner>
  </div>
</div>
