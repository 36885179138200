import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiGraphModule } from '../ui-graph/ui-graph.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CardErrorModule } from '../card-error/card-error.module';
import { DateSelectionModule } from '../date-selection/date-selection.module';
import { RouterModule } from '@angular/router';
import { OverviewKpisComponent } from './overview-kpis.component';
import { TableKpiComponent } from '../table/table-kpi/table-kpi.component';
import { CdkTableModule } from '@angular/cdk/table';
import { TooltipDirective } from '../table/tooltip-status/tooltip.directive';
import { SharedModule } from '../../shared.module';

@NgModule({
  declarations: [OverviewKpisComponent,TableKpiComponent],
  imports: [
    CommonModule,
    UiGraphModule,
    NgxSkeletonLoaderModule,
    SharedModule,
    TooltipDirective,
    CardErrorModule,
    RouterModule,
    CdkTableModule,
    DateSelectionModule
  ],
  exports: [OverviewKpisComponent,TableKpiComponent]
})
export class OverviewKpisModule { }
