import { Component, Input } from '@angular/core';
import { TooltipPosition, TooltipTheme } from '../table/tooltip-status/tooltip.enums';

@Component({
  selector: 'co2-overview-kpis',
  templateUrl: './overview-kpis.component.html',
  styleUrls: [ './overview-kpis.component.scss' ]
})
export class OverviewKpisComponent {
  @Input() equipmentOverview!: string | null;
  @Input() datePumps: string | undefined;
  @Input() globalheaders: any ;
  @Input() globalRows: any;
  @Input() globalDisplayedHeaders!: any;
  @Input() equipment!: string | null;
  @Input() detailsHeaders: any ;
  @Input() detailsRows!: any;

  @Input() detailsDisplayedHeaders!: any;
  protected readonly TooltipP = TooltipPosition;
  protected readonly TooltipT = TooltipTheme;
}
