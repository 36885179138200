import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OperationalParametersComponent } from './operational-parameters.component';
import { LoadingSpinnerModule } from 'src/modules/app/components/loading-spinner/loading-spinner.module';
import { UiGraphModule } from 'src/modules/app/components/ui-graph/ui-graph.module';
import { CardErrorModule } from 'src/modules/app/components/card-error/card-error.module';
import {
  OperationalParametersItemComponent
} from "./operational-parameters-item/operational-parameters-item.component";

@NgModule({
  declarations: [OperationalParametersComponent, OperationalParametersItemComponent],
  imports: [
    CommonModule,
    LoadingSpinnerModule,
    UiGraphModule,
    CardErrorModule,
    RouterModule,
  ],
  exports: [OperationalParametersComponent]
})
export class OperationalParametersModule { }
