import { ChangeDetectorRef, Component, OnChanges, TrackByFunction } from '@angular/core';
import { MachineStatus, TableHeader } from '../../../types';
import { TableComponent } from '../table.component';

@Component({
  selector: 'co2-table-kpi',
  templateUrl: './table-kpi.component.html',
  styleUrls: ['./table-kpi.component.scss']
})
export class TableKpiComponent extends TableComponent implements OnChanges {

  rowClass:string = '';
  trackHeader: TrackByFunction<TableHeader> = (index: number, header: TableHeader) => `${index}_${header.id}`;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnChanges() {
    this.cdr.detectChanges();
  }

  getRowClass(headerId: string, data: any): string {
    if(headerId === 'machineStatus') {
      if (data !== MachineStatus.ON) {
        this.rowClass = 'costum-background';
      } else {
        this.rowClass = 'transparent-background';
      }
    }
    if(headerId === 'equipmentStatus') {
      if (data !== MachineStatus.ON) {
        this.rowClass = 'costum-background';
      } else {
        this.rowClass = 'transparent-background';
      }
    }
    return this.rowClass;
  }
}
