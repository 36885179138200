<table *ngIf="rows" xmlns="http://www.w3.org/1999/html" class="table" cdk-table [dataSource]="rows"
       [class.disabled]="disabled" aria-describedby="table">
    <ng-container *ngFor="let header of headers; trackBy: trackHeader"  [cdkColumnDef]="header.id">
      <th scope="col" cdk-header-cell *cdkHeaderCellDef>
        <span [ngClass]="header.label === 'Avoidable emissions' ? 'd-block-fix' : '' " >{{header.label}}</span>
        <span class="material-icons-outlined" *ngIf="header.tooltip" [tooltip]="header.tooltip" [position]="TooltipPosition.ABOVE"  [theme]="TooltipTheme.LIGHT">info</span>
        <span *ngIf="header.subHeader" [ngClass]="header.label === 'Status' ? 'd-block-none' : ''" class="sub-header">{{header.subHeader}}</span>
      </th>
      <td cdk-cell *cdkCellDef="let row" [ngClass]="getRowClass(header.id, row[header.id])">
        <div *ngIf="header.dataType === DATA_TYPE.TEXT" class="table-data"
          [ngClass]="getDataClass(header.id, row[header.id])">
          <span> {{row[header.id] | displayNA}} </span>

          <span *ngIf="header.suffixText && row[header.id] !== undefined && row[header.id] !== null" class="suffix-text">
            {{header.suffixText}}
          </span>
          <span *ngIf="header.dynamicSuffix && !header.suffixText && row[header.id] !== undefined && row[header.id] !== null && row['energyType']">{{row['energyType'] === 'ELECTRIC' ? 'kW' : 'Sm3/h'}}</span>
        </div>

        <div *ngIf="header.dataType === DATA_TYPE.LIST && header.subIdTitle && header.id && header.subId && header.suffixText" class="table-data"
             [ngClass]="getDataClass(header.subId, row[header.subId])">
          <span *ngFor="let element of row[header.subId]">
            <span [innerHTML]="element[header.subIdTitle]+': '+(element[header.id] | displayNA)"></span>
            <span *ngIf="header.suffixText && element[header.id] !== null && element[header.id] !== undefined" [innerHTML]="header.suffixText"></span>
            <span *ngIf="header.subRefId" class="sub-field-ref">
              (ref: {{element[header.subRefId] | displayNA}}
                <span *ngIf="header.suffixText && element[header.subRefId] !== null && element[header.subRefId] !== undefined" [innerHTML]="header.suffixText"></span>)
            </span><br/>
          </span>
        </div>

        <div *ngIf="header.dataType === (DATA_TYPE.HTML_TEXT || DATA_TYPE.STATUS)" class="table-data"
          [ngClass]="getDataClass(header.id, row[header.id])">
          <span [innerHTML]="row[header.id]"> | displayNA</span>
          <span *ngIf="header.suffixText && row[header.id] !== undefined && row[header.id] !== null" class="suffix-text">
            {{header.suffixText}}
          </span>
          <div *ngIf="header.metadata[row['alertStatus']]" class="status">
          <span class="{{header.metadata[row['alertStatus']].statusClass}}">
            <span class="material-icons {{header.metadata[row['alertStatus']].iconClass}}">
                {{header.metadata[row['alertStatus']].icon}}
            </span>
            {{header.metadata[row['alertStatus']].label}} </span>
           <div class="status__days" *ngIf="header.metadata[row['alertStatus']].dateColumn">{{ daysFromNow(row[header.metadata[row['alertStatus']].dateColumn])}} days</div>
          </div>
        </div>

        <div>
          <div *ngIf="header.dataType === DATA_TYPE.BADGE" class="table-data badge badge-m"
               [style.--badgeColor]="getBadgeColors(row).color" style="white-space: pre-line;"
               [style.--badgeBackgroundColor]="getBadgeColors(row).badgeBackgroundColor">
            <span> {{row[header.id] | displayNA}} </span>

            <span *ngIf="header.suffixText && row[header.id] !== undefined && row[header.id] !== null" class="suffix-text">
              {{header.suffixText}}
            </span>
          </div>

          <div *ngIf="header.subHeaderId && row[header.subHeaderId] !== undefined && row[header.subHeaderId] !== null"
          class="table-data sub-header-id">x
            <span *ngIf="header.subHeaderId && row[header.subHeaderId] !== undefined && row[header.subHeaderId] !== null">
              {{row[header.subHeaderId]}}
            </span>
          </div>
        </div>
        <div *ngIf="header.dataType === DATA_TYPE.GRAPH" class="table-data cdk-header-row"
          [ngClass]="getDataClass(header.id, row[header.id])">
          <co2-ui-graph [options]="row[header.id]" class="inside-table"></co2-ui-graph>
        </div>
      </td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="displayedHeaders"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedHeaders" [class]="rowClass" [class.clickable]="clickableRows"
      (click)="onRowClick(row)" (keydown.enter)="onRowClick(row)" [class.selected]="rowIsSelected(row)" tabindex="0"></tr>

  </table>
