<div class="card">
  <div class="card-title">
    <div>{{equipmentOverview}} <span class="additional-text" *ngIf="datePumps">{{ datePumps }} 10:00 PM</span></div>
  </div>

  <div class="card-body overview-body">

    <div class="card global-power">

      <div class="card-body">
        <co2-table-kpi [headers]="globalheaders"
                       [rows]="[globalRows]"
                       [displayedHeaders]="globalDisplayedHeaders">
        </co2-table-kpi>

      </div>
    </div>

    <div class="accordion noshaddow">
      <div class="accordion-item">
        <h4 class="accordion-header d-flex" id="accordion-btn-header">
          <button class="accordion-button collapsed flex-grow-1" type="button" data-bs-toggle="collapse"
            data-bs-target="#accordion-btn-collapse" aria-expanded="true" aria-controls="accordion-btn-collapse">
            Details per {{equipment}}

          </button>
        </h4>
        <div id="accordion-btn-collapse" class="accordion-collapse collapse" aria-labelledby="accordion-btn-header">
          <div class="accordion-body">
            <co2-table-kpi
              [headers]="detailsHeaders"
              [rows]="detailsRows"
              [displayedHeaders]="detailsDisplayedHeaders">
            </co2-table-kpi>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
